<template>
    <v-form ref="submit" v-if="step === 4">
        <form-section
            :sectionTitle="$t('applications.review-and-submit')"
            wideFirstCol
        >
            <template v-slot:col-1>
                <form-element-group>
                    <template v-slot:col-1>
                        <div>
                            <v-alert
                                class="mt-0"
                                border="top"
                                color="accent"
                                icon="mdi-clipboard-check"
                                dark
                                prominent
                            >
                                <p>{{ $t("applications.submit-review") }}</p>
                            </v-alert>
                            <psi-content-loader
                                type="circular"
                                v-if="dataLoading"
                            ></psi-content-loader>
                            <v-simple-table v-else>
                                <template v-slot:default>
                                    <thead></thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                { key, label }, index
                                            ) in items"
                                            :key="index"
                                        >
                                            <td>{{ label }}</td>
                                            <td>
                                                {{
                                                    key === "ssn"
                                                        ? displaySsn(
                                                              profile[key]
                                                          )
                                                        : profile[key]
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-card flat>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :loading="dataLoading"
                                        color="secondary"
                                        block
                                        @click="submitApplication"
                                    >
                                        <v-icon class="mr-1"
                                            >mdi-check-circle</v-icon
                                        >
                                        {{
                                            $t(
                                                "applications.submit-application"
                                            )
                                        }}</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </div>
                    </template>
                </form-element-group>
            </template>
            <template v-slot:col-2></template>
        </form-section>
        <v-row>
            <v-col offset-md="2">
                <v-btn
                    class="my-2 mr-4"
                    @click="prevStep"
                    :disabled="dataLoading"
                    ><v-icon class="mr-1 grey--text text--darken-2"
                        >mdi-chevron-left-circle</v-icon
                    >{{ $t("applications.back") }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "applicant-submit",
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
        currentStep: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            items: [
                {
                    label: this.$t("applications.name"),
                    key: "name",
                },
                {
                    label: this.$t("applications.birthdate"),
                    key: "birthdate",
                },
                {
                    label: this.$t("applications.email"),
                    key: "email",
                },
                {
                    label: this.$t("applications.phone"),
                    key: "mobile_phone",
                },
                {
                    label: this.$t("applications.ssn"),
                    key: "ssn",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Applications", ["dataLoading"]),
        profile() {
            return this.applicant.profile;
        },
    },
    watch: {
        currentStep: {
            immediate: true,
            handler(currentStep) {
                if (
                    currentStep === 4 &&
                    Object.keys(this.applicant).length > 0
                ) {
                    this.getApplicant({
                        application_id: this.applicant.application.id,
                        applicant_id: this.applicant.id,
                    });
                }
            },
        },
        applicant: {
            deep: true,
            handler(applicant) {
                if (applicant.status === "Complete") {
                    // TODO: Check Role on Application Submit

                    // window.location.replace(
                    //     this.$utils.buildUrl("portal", "orders/history")
                    // );
                    this.$emit("next", parseInt(this.step) + 1);
                }
            },
        },
    },
    mounted() {},
    created() {},
    updated() {},
    methods: {
        ...mapActions("Applications", ["getApplicant"]),
        submitApplication() {
            this.$emit("submit:applicant");
        },
        prevStep() {
            this.$emit("previous", parseInt(this.step) - 1);
        },
        displaySsn(ssn) {
            return ssn
                ? `###-##-${ssn.slice(-4)}`
                : this.$t("applications.no-ssn-available");
        },
    },
};
</script>

<style scoped>
</style>