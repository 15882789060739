var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.step === 4
    ? _c(
        "v-form",
        { ref: "submit" },
        [
          _c("form-section", {
            attrs: {
              sectionTitle: _vm.$t("applications.review-and-submit"),
              wideFirstCol: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "col-1",
                  fn: function() {
                    return [
                      _c("form-element-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "col-1",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-0",
                                          attrs: {
                                            border: "top",
                                            color: "accent",
                                            icon: "mdi-clipboard-check",
                                            dark: "",
                                            prominent: ""
                                          }
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "applications.submit-review"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm.dataLoading
                                        ? _c("psi-content-loader", {
                                            attrs: { type: "circular" }
                                          })
                                        : _c("v-simple-table", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function() {
                                                    return [
                                                      _c("thead"),
                                                      _c(
                                                        "tbody",
                                                        _vm._l(
                                                          _vm.items,
                                                          function(ref, index) {
                                                            var key = ref.key
                                                            var label =
                                                              ref.label
                                                            return _c(
                                                              "tr",
                                                              { key: index },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      label
                                                                    )
                                                                  )
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        key ===
                                                                          "ssn"
                                                                          ? _vm.displaySsn(
                                                                              _vm
                                                                                .profile[
                                                                                key
                                                                              ]
                                                                            )
                                                                          : _vm
                                                                              .profile[
                                                                              key
                                                                            ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              1805859987
                                            )
                                          }),
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    loading: _vm.dataLoading,
                                                    color: "secondary",
                                                    block: ""
                                                  },
                                                  on: {
                                                    click: _vm.submitApplication
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-1" },
                                                    [_vm._v("mdi-check-circle")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "applications.submit-application"
                                                        )
                                                      )
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1913004455
                        )
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "col-2",
                  fn: function() {
                    return undefined
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3922925114
            )
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { "offset-md": "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "my-2 mr-4",
                      attrs: { disabled: _vm.dataLoading },
                      on: { click: _vm.prevStep }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1 grey--text text--darken-2" },
                        [_vm._v("mdi-chevron-left-circle")]
                      ),
                      _vm._v(_vm._s(_vm.$t("applications.back")))
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }